import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import sortArray from 'sort-array';
const getEmails = require('get-emails');
const men_hash_extract = require('mention-hashtag');

const pos = require('pos');
const nlp = require('wink-nlp-utils');

const lda = require('lda');

const sentenceTokenizer = require('sbd');
const Sentiment = require('sentiment');
const sentiment = new Sentiment();

import { findWordsKeywords } from '../config/config';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    leaveDelay: 50,
  },
}))(Tooltip);

const parseArr = (arr_inds, string_val) => {
  if (arr_inds === undefined || arr_inds.length === 0) {
    return 'Not found!';
  }
  var results = [];
  arr_inds.forEach(function (value) {
    const res = string_val.slice(value.index, value.index + value.offset);
    results.push(res);
  });
  return results.join('\n');
};

const writeGoodParser = (write_good_result) => {
  if (write_good_result === undefined || write_good_result.length === 0) {
    return 'Not found!';
  }
  return write_good_result.flatMap((value) => value.reason).join('\n');
};

const compromise = (input_text, what_to_find) => {
  // eslint-disable-next-line default-case

  let return_result = undefined;
  // eslint-disable-next-line default-case
  switch (what_to_find) {
    case 'emails': {
      const emails_found = Array.from(getEmails(input_text));
      if (emails_found === undefined || emails_found.length === 0) {
        return_result = ['No emails found!'];
        break;
      }
      return_result = emails_found;
      break;
    }
    case 'mentions': {
      const mentions_found = men_hash_extract(input_text);
      if (mentions_found === undefined || mentions_found.length === 0) {
        return_result = ['No @mentions found!'];
        break;
      }
      return_result = mentions_found;
      break;
    }
    case 'hashtags': {
      const hashtags_found = men_hash_extract(input_text, '#');
      if (hashtags_found === undefined || hashtags_found.length === 0) {
        return_result = ['No #hashtags found!'];
        break;
      }
      return_result = hashtags_found;
      break;
    }
  }
  return return_result.join('\n');
};

const cleaner = (input_token) => {
  if (input_token === undefined || input_token.length <= 1) {
    return '';
  }
  return input_token;
};

const getAdjPhrases = (input_text) => {
  const sentences = nlp.string.sentences(input_text);
  if (sentences === undefined || sentences.length === 0) {
    return 'Not Found!';
  }
  let phrases = [];
  for (let s = 0; s < sentences.length; s++) {
    var words = new pos.Lexer().lex(sentences[s]);
    var tagger = new pos.Tagger();
    var taggedWords = tagger.tag(words);

    for (let i = 0; i < taggedWords.length; i++) {
      if (
        taggedWords[i][1] === 'JJ' ||
        taggedWords[i][1] === 'JJR' ||
        taggedWords[i][1] === 'JJS'
      ) {
        try {
          const phrase =
            cleaner(taggedWords[i - 1][0]) +
            ' ' +
            cleaner(taggedWords[i][0]) +
            ' ' +
            cleaner(taggedWords[i + 1][0]);
          phrases.push(phrase);
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
  if (phrases === undefined || phrases.length === 0) {
    return 'Not Found!';
  }
  return phrases.join('\n');
};

/**
 * Calculates the frequency of each word
 * @param {array} wordList
 */
const calculateWordFrequency = (wordList) => {
  const counts = {};
  const phrases = [];
  wordList.forEach((value) => (counts[value] = (counts[value] || 0) + 1));
  Object.keys(counts).forEach((word) => {
    phrases.push(`${word}: ${counts[word]}`);
  });
  return phrases.join('\n');
};

function removeFromString(arr, str) {
  let regex = new RegExp('\\b' + arr.join('|') + '\\b', 'gi');
  return str.replace(regex, '');
}

const getLDA = (text, numberOfTopics) => {
  const documents = text.match(/[^\.!\?]+[\.!\?]+/g);
  const results = lda(documents, numberOfTopics, findWordsKeywords);
  const phrases = [];
  const wordcloud = [];
  results.forEach((keywords, i) => {
    phrases.push(`Topic ${i + 1}: \n`);
    const sortedKeywords = sortArray(keywords, { by: 'term', order: 'desc' });
    wordcloud.push(sortedKeywords.map(({ term }) => term));
    sortedKeywords.forEach(({ term }) => {
      phrases.push(`\t- ${term}\n`);
    });
    phrases.push('------------------------\n');
  });
  return {
    phrase: phrases.join(''),
    wordcloud,
  };
};

const analizeSentiment = (text) => {
  const sentences = sentenceTokenizer.sentences(text);
  const sentiments = sentences.map((sentence) => ({
    sentence,
    sentiment: sentiment.analyze(sentence),
  }));

  const wordcloud = (sign) => [
    ...new Set(
      sentiments
        .filter(({ sentiment: { score } }) =>
          sign === 1 ? score > 0 : score < 0
        )
        .map(({ sentiment: { tokens } }) => tokens)
        .flat()
    ),
  ];

  return {
    positiveWordcloud: wordcloud(1),
    negativeWordcloud: wordcloud(-1),
    sentiments: sortArray(
      sentiments.map(({ sentence, sentiment }) => ({
        score: sentiment.score,
        sentence,
      })),
      { by: 'score', computed: { score: (sentiment) => sentiment.score } }
    ),
  };
};

export {
  removeFromString,
  LightTooltip,
  HtmlTooltip,
  parseArr,
  compromise,
  writeGoodParser,
  getAdjPhrases,
  cleaner,
  calculateWordFrequency,
  getLDA,
  analizeSentiment,
};
