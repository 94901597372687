const Wordcloud = require('wordcloud');

/**
 * Plots the wordclouds
 * @param { title: string, list: Array<string> } data
 * @param { width: number, height: number } options
 */
export const plotWordcloud = (
  data,
  options = { width: 300, height: 300, hasTitle: true }
) => {
  const wordcloudContainer = document.getElementById('wordclouds-canvas');
  wordcloudContainer.innerHTML = '';

  data.map((cloud, index) => {
    if (cloud.list.length > 0) {
      const id = 'canvas-' + index + 1;
      const d = document.createElement('div');
      const c = document.createElement('canvas');
      c.setAttribute('id', id);
      c.setAttribute('width', options.width);
      c.setAttribute('height', options.height);
      c.setAttribute(
        'class',
        'wordcloud border border-dashed border-gray-400 rounded-sm'
      );

      // render title
      if (options.hasTitle) {
        const text = document.createElement('div');
        text.innerHTML = cloud.title ?? 'Title ' + (index + 1);
        text.style.paddingBottom = '10px';
        d.appendChild(text);
      }

      d.appendChild(c);
      wordcloudContainer.appendChild(d);

      Wordcloud(c, {
        list: cloud.list.map((d) => [d, 8 + Math.floor(Math.random() * 30)]),
      });

      const downloadButton = document.createElement('button');
      downloadButton.innerHTML = 'Download Image';
      downloadButton.className =
        'py-1 rounded-sm text-sm text-white mt-1 text-blue-500';
      downloadButton.onclick = function () {
        const a = document.createElement('a');
        a.download = 'wordcloud.png';
        a.href = document.getElementById(id).toDataURL();
        document.body.appendChild(a);
        a.click();
        a.remove();
      };
      d.appendChild(downloadButton);
    }
  });
};
