const confetti = require('canvas-confetti');

export const plotConfetti = () => {
  const canvas = document.getElementById('confetti');

  const confettiInstance = confetti.create(canvas, {
    resize: true,
    useWorker: true,
  });

  confettiInstance({
    particleCount: 100,
    spread: 160,
    origin: { y: 0.6 },
  });
};
