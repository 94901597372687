import React from 'react';
import ReactGA from 'react-ga';
import voca from 'voca';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { IoMdCloud } from 'react-icons/io';
import { MdEmail, MdDashboard } from 'react-icons/md';
import { GoMention } from 'react-icons/go';
import { GiImpactPoint } from 'react-icons/gi';
import { RiPencilFill } from 'react-icons/ri';
import { FaHashtag, FaStar, FaListUl } from 'react-icons/fa';
import {
  TbLetterCaseUpper,
  TbLetterCaseLower,
  TbLetterCase,
  TbLetterCaseToggle,
} from 'react-icons/tb';

import {
  HtmlTooltip,
  parseArr,
  compromise,
  writeGoodParser,
  getAdjPhrases,
  calculateWordFrequency,
  getLDA,
  removeFromString,
  analizeSentiment,
} from './components/customTooltips';
import { plotWordcloud } from './utils/plotWordcloud';
import { plotConfetti } from './utils/plotConfetti';
import { SentimentTable } from './components/sentimentTable';
import { ButtonSeparator } from './components/buttonSeparator';
import { WordCloudTip } from './components/wordCloudTip';
import TextDistillLogo from './assets/images/textstilllogo.png';

import './App.css';

const movieQuote = require('popular-movie-quotes');
const cliches = require('no-cliches');
const complexity = require('too-wordy');
const writeGood = require('write-good');

const Tokenizer = require('tokenize-text');
const tokenize = new Tokenizer();

ReactGA.initialize('UA-139380220-3');

import { contactEmail } from './config/config';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      wordCloudVisible: false,
      wordCloudTooltipVisible: false,
      input_text: '',
      transformed_text: '',
      numberOfTopics: '',
      findTopicsInputVisible: false,
      stopWords: '',
      findTopicsVisible: false,
      sentimentData: null,
      outputVisible: true,
    };
    this.convertCase = this.convertCase.bind(this);
    this.insertSample = this.insertSample.bind(this);
    this.insertComplexSample = this.insertComplexSample.bind(this);
    this.findTopics = this.findTopics.bind(this);
    this.gotoInitialState = this.gotoInitialState.bind(this);
  }

  insertSample() {
    //console.log(movieQuote.getSomeRandom(1)[0].quote);
    const movieqt = movieQuote.getSomeRandom(1)[0];
    ReactGA.event({ category: 'Button', action: 'movie quote - sample 1' });
    this.setState({
      input_text: movieqt.quote + '\n-- from ' + movieqt.movie,
    });
  }

  insertComplexSample() {
    ReactGA.event({ category: 'Button', action: 'complex sample' });
    this.setState({
      input_text:
        'Read some #twitter #hashtags from @popular and @interesting folks until the cows come home. Send some emails to folks@example.com when you are feeling happy and ecstatic.',
    });
  }

  convertCase(type_of_conversion) {
    this.setState({ findTopicsInputVisible: false });
    this.setState({ findTopicsVisible: false });
    this.setState({ wordCloudVisible: false });
    this.setState({ sentimentData: null });
    this.setState({ outputVisible: true });
    this.setState({ wordCloudTooltipVisible: false });

    if (type_of_conversion === 'sentiment') {
      this.setState({ outputVisible: false });
    }

    switch (type_of_conversion) {
      case 'uppercase': {
        ReactGA.event({ category: 'Button', action: 'Uppercase' });
        this.setState({
          transformed_text: voca.upperCase(this.state.input_text),
        });
        break;
      }
      case 'lowercase': {
        ReactGA.event({ category: 'Button', action: 'lowercase' });
        this.setState({
          transformed_text: voca.lowerCase(this.state.input_text),
        });
        break;
      }
      case 'titlecase': {
        ReactGA.event({ category: 'Button', action: 'titlecase' });
        this.setState({
          transformed_text: voca.titleCase(this.state.input_text),
        });
        break;
      }
      case 'camelcase': {
        ReactGA.event({ category: 'Button', action: 'camelcase' });
        this.setState({
          transformed_text: voca.camelCase(this.state.input_text),
        });
        break;
      }
      case 'cliches': {
        ReactGA.event({ category: 'Button', action: 'cliches' });
        const p = cliches(this.state.input_text);
        this.setState({ transformed_text: parseArr(p, this.state.input_text) });
        break;
      }
      case 'wordy': {
        const p = complexity(this.state.input_text);
        this.setState({ transformed_text: parseArr(p, this.state.input_text) });
        break;
      }
      case 'emails': {
        ReactGA.event({ category: 'Button', action: 'emails' });
        this.setState({
          transformed_text: compromise(this.state.input_text, 'emails'),
        });
        break;
      }
      case 'mentions': {
        ReactGA.event({ category: 'Button', action: 'mentions' });
        this.setState({
          transformed_text: compromise(this.state.input_text, 'mentions'),
        });
        break;
      }
      case 'hashtags': {
        ReactGA.event({ category: 'Button', action: 'hashtags' });
        this.setState({
          transformed_text: compromise(this.state.input_text, 'hashtags'),
        });
        break;
      }
      case 'sentiment': {
        ReactGA.event({ category: 'Button', action: 'sentiment' });
        if (this.state.input_text !== '') {
          plotConfetti();
          const sentimentData = analizeSentiment(this.state.input_text);
          this.setState({ sentimentData }, () => {
            plotWordcloud([
              {
                title: 'Positive Wordcloud',
                list: sentimentData.positiveWordcloud,
              },
              {
                title: 'Negative Wordcloud',
                list: sentimentData.negativeWordcloud,
              },
            ]);

            this.setState({ transformed_text: 'Sentiment analyzed!' });
          });
        } else {
          this.setState({ transformed_text: 'Not Found!' });
        }
        break;
      }
      case 'writegood': {
        ReactGA.event({ category: 'Button', action: 'writegood' });
        const suggestions = writeGood(this.state.input_text);
        this.setState({ transformed_text: writeGoodParser(suggestions) });
        break;
      }
      case 'impactphrases': {
        ReactGA.event({ category: 'Button', action: 'impactphrases' });
        this.setState({
          transformed_text: getAdjPhrases(this.state.input_text),
        });
        break;
      }
      case 'wordcloud': {
        ReactGA.event({ category: 'Button', action: 'wordcloud' });

        if (this.state.input_text !== '') {
          this.setState({ wordCloudVisible: true }, () => {
            // const canvas = document.getElementById('canvas');
            const tokens = tokenize
              .words()(this.state.input_text)
              .map(({ value }) => value);

            plotWordcloud([{ list: tokens }], {
              width: 900,
              height: 400,
              hasTitle: false,
            });

            this.setState({
              transformed_text: calculateWordFrequency(tokens),
              wordCloudTooltipVisible: true,
            });
          });
        } else {
          this.setState({
            wordCloudVisible: false,
            transformed_text: 'Not Found!',
            wordCloudTooltipVisible: false,
          });
        }
        break;
      }
    }
  }

  findTopics() {
    ReactGA.event({ category: 'Button', action: 'topics' });
    this.setState({ findTopicsVisible: true }, () => {
      const filteredText = removeFromString(
        this.state.stopWords.split(' '),
        this.state.input_text
      );

      const { phrase, wordcloud } = getLDA(
        filteredText,
        this.state.numberOfTopics
      );

      this.setState({
        transformed_text: phrase,
      });

      plotWordcloud(
        wordcloud.map((list, index) => ({ list, title: `Topic ${index + 1}` }))
      );
    });
  }

  gotoInitialState() {
    this.setState({
      wordCloudVisible: false,
      input_text: '',
      transformed_text: '',
      numberOfTopics: '',
      findTopicsInputVisible: false,
      stopWords: '',
      findTopicsVisible: false,
      sentimentData: null,
      outputVisible: true,
    });
  }

  render() {
    return (
      <>
        <div className="bg-white px-8 py-2 flex items-center border-b-2 border-b-slate-200">
          <div className="w-[60px]" onClick={this.gotoInitialState}>
            <img
              src={TextDistillLogo}
              alt="textdistill-logo"
              className="w-full"
            />
          </div>

          <div className="ml-20">
            <h1 className="font-bold text-lg tracking-widest">TEXT DISTILL</h1>
            <p className="text-xs text-slate-400 tracking-widest">
              Smart tool for text mining
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="w-[300px] border-r-2 border-slate-200 flex flex-col p-4 gap-4">
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Find word frequency and plot wordcloud
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="default"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                onClick={() => this.convertCase('wordcloud')}
                className="simple_button"
                startIcon={<IoMdCloud />}
              >
                <Typography variant="button" display="block">
                  WORDCLOUD
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Find Topics</Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="default"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<FaListUl />}
                onClick={() => {
                  this.convertCase();
                  this.setState({ findTopicsInputVisible: true });
                }}
              >
                <Typography variant="button" display="block">
                  Find Topics
                </Typography>
              </Button>
            </HtmlTooltip>

            <ButtonSeparator />

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    E.g., two words -&gt; TWO WORDS
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<TbLetterCaseUpper />}
                onClick={() => this.convertCase('uppercase')}
              >
                <Typography variant="button" display="block">
                  upper case
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    E.g., TwO wOrds -&gt; two words
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<TbLetterCaseLower />}
                onClick={() => this.convertCase('lowercase')}
              >
                <Typography variant="button" display="block">
                  lower case
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    E.g., two words -&gt; <b>{'T'}</b>wo <b>{'W'}</b>
                    ords
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<TbLetterCase />}
                onClick={() => this.convertCase('titlecase')}
              >
                <Typography variant="button" display="block">
                  title case
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    E.g., two words -&gt; two<b>{'W'}</b>ords
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<TbLetterCaseToggle />}
                onClick={() => this.convertCase('camelcase')}
              >
                <Typography variant="button" display="block">
                  camel case
                </Typography>
              </Button>
            </HtmlTooltip>

            <ButtonSeparator />

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Extracts email addresses from text.
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<MdEmail />}
                onClick={() => this.convertCase('emails')}
              >
                <Typography variant="button" display="block">
                  Extract Emails
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Extracts @mentions from text
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<GoMention />}
                onClick={() => this.convertCase('mentions')}
              >
                <Typography variant="button" display="block">
                  Extract @Mentions
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Extracts #hashtags from text
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<FaHashtag />}
                onClick={() => this.convertCase('hashtags')}
              >
                <Typography variant="button" display="block">
                  Extract #hashtags
                </Typography>
              </Button>
            </HtmlTooltip>

            <ButtonSeparator />

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    E.g., You can use cliches until the cows come home -&gt;
                    until the cows come home
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<MdDashboard />}
                onClick={() => this.convertCase('cliches')}
                className="simple_button"
              >
                <Typography variant="button" display="block">
                  Find Cliches
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Analyzes the sentiment of your text and provides an overall
                    score.
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<FaStar />}
                onClick={() => this.convertCase('sentiment')}
              >
                <Typography variant="button" display="block">
                  ANALYZE SENTIMENT
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Analyzes your writing and suggests improvements.
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<RiPencilFill />}
                onClick={() => this.convertCase('writegood')}
              >
                <Typography variant="button" display="block">
                  ANALYZE WRITING STYLE
                </Typography>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Finds phrases that are high impact.
                  </Typography>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#1C65C2',
                  color: '#ffffff',
                }}
                startIcon={<GiImpactPoint />}
                onClick={() => this.convertCase('impactphrases')}
                className="simple_button"
              >
                <Typography variant="button" display="block">
                  Find Impact Phrases
                </Typography>
              </Button>
            </HtmlTooltip>
          </div>

          <div className="flex flex-1">
            <div className="p-4 flex-1">
              <div className="mb-4 flex gap-4">
                <Button
                  variant="contained"
                  color="default"
                  onClick={this.insertSample}
                  className="simple_button"
                >
                  Insert Demo Text
                </Button>
              </div>

              <div>
                <textarea
                  onChange={(event) => {
                    this.setState({
                      input_text: event.target.value,
                    });
                  }}
                  value={this.state.input_text}
                  placeholder="Paste your text here..."
                  className="border border-dashed border-gray-400 rounded-sm p-2"
                >
                  {this.state.input_text}
                </textarea>

                {this.state.findTopicsInputVisible && (
                  <div className="flex items-center mt-1">
                    <input
                      type="text"
                      className="border border-dashed border-gray-400 rounded-sm p-2 w-[200px] mr-2"
                      placeholder="Number of topics"
                      value={this.state.numberOfTopics}
                      onChange={(e) =>
                        this.setState({ numberOfTopics: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      className="border border-dashed border-gray-400 rounded-sm p-2 flex-1 mr-2"
                      placeholder="Enter stop words separated by space Eg: word1 word2"
                      value={this.state.stopWords}
                      onChange={(e) =>
                        this.setState({ stopWords: e.target.value })
                      }
                    />
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => this.findTopics()}
                      className="simple_button"
                    >
                      PROCEED
                    </Button>
                  </div>
                )}

                {this.state.outputVisible && (
                  <div className="relative output">
                    <textarea
                      value={this.state.transformed_text}
                      placeholder="Output will appear here"
                      className="border border-dashed border-gray-400 rounded-sm p-2 mt-3"
                      disabled
                    ></textarea>
                    <button
                      className="absolute top-5 right-2 bg-blue-500 text-white px-2 text-sm rounded-sm pb-1"
                      onClick={() => {
                        this.setState({ isCopied: true }, () => {
                          if (this.state.isCopied) {
                            setTimeout(() => {
                              this.setState({ isCopied: false });
                            }, 1000);
                          }
                        });
                        navigator.clipboard.writeText(
                          this.state.transformed_text
                        );
                      }}
                    >
                      {this.state.isCopied ? 'Copied!' : 'Copy'}
                    </button>
                  </div>
                )}

                {/* Sentiment table */}
                {this.state.sentimentData && (
                  <SentimentTable data={this.state.sentimentData} />
                )}

                {/* Wordcloud container */}
                {(this.state.findTopicsVisible ||
                  this.state.sentimentData ||
                  this.state.wordCloudVisible) && (
                  <div
                    id="wordclouds-canvas"
                    className="flex gap-4 mt-2 flex-wrap"
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col items-center justify-center gap-2 bg-white p-2 border-t-2 border-t-slate-200">
          <div className="flex items-center justify-center gap-2">
            <a
              href="https://www.websitepolicies.com/policies/view/dHD0V3G6"
              target="_blank"
              className="hover:underline"
            >
              Privacy policy
            </a>
            /
            <a
              href="https://www.websitepolicies.com/policies/view/V58OdczM"
              target="_blank"
              className="hover:underline"
            >
              Disclaimer
            </a>
            / contact@textdistill.com
          </div>

          <div className="text-center border-t border-gray-300 pt-2">
            <span className="text-gray-400">Email </span>
            <a
              href={`mailto:${contactEmail}`}
              className="text-blue-600 cursor-pointer hover:underline"
            >
              {contactEmail}
            </a>
            <span className="text-gray-400">
              {' '}
              to use our APIs for large volume queries and custom solutions
            </span>
          </div>
        </div>

        <WordCloudTip open={this.state.wordCloudTooltipVisible} />
      </>
    );
  }
}

export default App;
