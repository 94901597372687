import { useEffect, useRef, useState } from 'react';

export const WordCloudTip = ({ open }) => {
  const [visible, setVisible] = useState(open);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setVisible(open);
  }, [open]);

  useEffect(() => {
    if (visible) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
  }, [visible]);

  return (
    visible && (
      <div className="fixed bottom-4 left-[50%] translate-x-[-50%] bg-blue-200 border border-gray-300 px-4 py-2 rounded-lg fadeAnimation">
        Look for the result below!
      </div>
    )
  );
};
